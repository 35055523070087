<template>
  <div class="page_body">
    <div class="account_infomation">
      <div class="account">
        <p>申请提现</p>
        <span>可提现{{amount}}元，待结算 {{pending_money}}元</span>
      </div>
      <!-- 账户信息 -->
      <div class="infomation_form">
        <van-form  :show-error="false" :show-error-message="true">
          <div class="form_item">
            <p>收款方式</p>
            <van-field
              readonly
              clickable
              name="picker"
              :value="value"
              placeholder="支付宝"
              @click="showPicker = true"
            />
            <img class="right_icon" src="../../../assets/pay/tjf_gzh_zhxx_next_icon.png" alt />

            <div class="across"></div>
          </div>
          <div class="form_item" v-if="value=='支付宝'">
            <p>真实姓名</p>
            <van-field
              v-model="alipay_realname"
              name="alipay_realname"
              placeholder="请输入真实姓名"
              :rules="[{ trigger: true,required:true, message: '请填写真实姓名' }]"
            />
            <div class="across"></div>
          </div>
          <div class="form_item" v-if="value=='支付宝'">
            <p>收款账号</p>
            <van-field
              v-model="alipay_account"
              name="alipay_account"
              placeholder="请输入收款账号"
              :rules="[{ validator,trigger: true,required:true, message: '请正确填写收款账号' }]"
            />
            <div class="across"></div>
          </div>
          <div class="form_item">
            <p>提现金额</p>
            <div class="rmb">¥</div>
            <van-field
              class="withdraw_sum"
              v-model="moneyActive"
              name="moneyActive"
              placeholder="最低可提现一元"
              :rules="[{ trigger: true,required:true, message: '请填写提现金额' }]"
            />
            <div class="across"></div>
          </div>
          <van-button
            class="withdraw_btn"
            native-type="submit"
            :loading="btn_loading"
            size="large"
            @click="onSubmit"
          >提现</van-button>
        </van-form>
        <van-button class="withdraw_record" @click="get_record">提现记录</van-button>
      </div>
      <!-- 提示 -->

      <div class="withdraw_tip">
        <p>
          1、可提现余额满1元即可申请提现，无需手续费
          <br />2、请确保您的支付宝账号和姓名一致
          <br />3、每月25号结算上个月的已收货订单
          <br />4、如想使用其他收款方式请联系客服处理
          <br />5、如有其它问题或疑问，请及时联系客服处理
          <br />
          6、客服微信: {{customer_account}}
        </p>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import StatusBar from "@/components/StatusBar";
import NavBar from "@/components/NavBar";
import ls from "@/libs/localstore";
import { Picker, Cell, Button, Form, Popup, Field } from "vant";
import PinkBtn from "@/components/PinkBtn";
import { getWithdrawTimes, withDrawCashApply,getCustomerInfo } from "@/api/wallet";

Vue.use(Picker).use(Cell).use(Button).use(Form).use(Popup).use(Field);

require("@/libs/validate.js");

export default {
  name: "WithDrawCashApply",
  components: {
    StatusBar,
    NavBar,
    PinkBtn,
  },
  data() {
    return {
      btn_loading: false,
      tipshow: false,
      amount: ls.getUserAmount(),
      withdrawTimes: 0,
      payActive: 1,
      pending_money: 0,
      moneyActive: "",
      alipay_account: "",
      alipay_realname: "",
      img: {},
      columns: ["支付宝", "微信"],
      showPicker: false,
      password: "",
      value: "支付宝",
      customer_account: "",
    };
  },
  mounted() {
    this.getData();
    this._getCustomerInfo()
  },
  computed: {
    withdrawIsNotNull() {
      return this.withdrawData.length != 0;
    },
  },
  methods: {
     //获取客服微信号
    _getCustomerInfo() {
      getCustomerInfo().then((res) => {
        if (res.errCode == 0) {
          this.customer_account = res.data.customer_account;
        }
      });
    },
    //正则
    validator(val) {
      return /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(val);
    },
    onConfirm(value) {
      this.value = value;
      if (this.value == "支付宝") {
        this.payActive = 2;
      } else {
        this.payActive = 1;
      }
      this.showPicker = false;
    },
    onSubmit(values) {
      this.apply();
    },
    get_record() {
      this.$router.push("/withdrawCash");
    },
    moneyChange(index) {
      this.moneyActive = index;
    },
    getData() {
      let params = {
         
      };
      getWithdrawTimes(params)
        .then((res) => {
          if (res.errCode == 0) {
            this.amount = res.data.money;
            this.withdrawTimes = res.data.rebate_times;
            this.pending_money = res.data.pending_money;
          } else {
            this.$toast(res.errMsg);
          }
        })
        .catch((err) => {
          this.$toast(err.errMsg);
          // 做些错误处理
        });
    },
    //提现
    apply() {
      this.btn_loading = true;
      let params = {
         
        method: this.payActive,
        money: this.moneyActive,
        alipay_account: this.alipay_account,
        alipay_realname: this.alipay_realname,
      };
      withDrawCashApply(params)
        .then((res) => {
          if (res.errCode == 0) {
            ls.setUserAmount(res.data.money);
            this.$toast.success("申请已提交");
            this.$router.push("/withdrawCash");
          } else if (res.errCode == "ERR_00005") {
            this.$toast(res.errMsg);
            this.$router.push("/identityCard");
          } else {
            this.$toast(res.errMsg);
          }
          this.btn_loading = false;
        })
        .catch((err) => {
          this.btn_loading = false;
          this.$toast(err.errMsg);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f6f6f6;
}
.account_infomation {
  .account {
    margin-bottom: 10px;
    width: 375px;
    height: 100px;
    background: linear-gradient(
      270deg,
      rgba(255, 108, 115, 1),
      rgba(255, 85, 149, 1)
    );
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    padding: 24px 0 27px 18.5px;
    box-sizing: border-box;
    p {
      line-height: 19px;
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 14px;
    }
    span {
      font-size: 15px;
      line-height: 15px;
      font-weight: 400;
    }
  }
  .infomation_form {
    background-color: #fff;
    padding-bottom: 25px;
    .van-form {
      overflow: hidden;
      .form_item {
        position: relative;
        p {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(68, 68, 68, 1);
          margin: 24.5px 0 0 19px;
        }
        .van-cell {
          padding: 22px 0 13px 19px;
          box-sizing: border-box;
          font-size: 16px;
          border: none;
          line-height: 1;
          font-weight: 400;
          overflow: visible;
          .van-cell__value {
            font-size: 16px;
            color: #b9b9b9;
            font-weight: 400;
          }
          .van-field__error-message {
            position: absolute;
            top: 40px;
            font-size: 13px;
            color: #ff3942;
          }
        }
        .van-cell::after {
          border: none;
        }
        .across {
          position: absolute;
          width: 337px;
          height: 1px;
          left: 19px;
          bottom: 0;
          background: rgba(241, 241, 241, 1);
        }
        .right_icon {
          position: absolute;
          right: 19px;
          top: 40px;
          width: 8px;
          height: 14px;
        }
        .rmb {
          position: absolute;
          left: 19px;
          font-size: 20px;
          color: #333333;
          font-weight: 600;
          z-index: 9;
          top: 37px;
        }
        .withdraw_sum {
          .van-field__control {
            text-indent: 27px;
          }
        }
      }
    }

    .van-button {
      width: 337px;
      border: 1px solid rgba(255, 97, 132, 1);
      color: #ff6184;
      font-size: 17px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin: 15px 19px 0;
      border-radius: 5px;
    }
    .withdraw_btn {
      margin-top: 47.5px;
      background: linear-gradient(
        270deg,
        rgba(255, 108, 115, 1),
        rgba(255, 85, 149, 1)
      );
      border: none;
      color: #ffffff;
    }
  }
  .withdraw_tip {
    margin: 10px 0 50px;
    padding: 15px 18.5px 16px;
    background: rgba(253, 247, 224, 1);
    p {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(94, 87, 71, 1);
      line-height: 20px;
    }
  }
}
.van-popup {
  border-radius: 20px 20px 0 0;
}
</style>
